
<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>

<template>
  <div class="home">
	  <div class=""></div>
  </div>
</template>

